import { forwardRef , type ReactNode, type HTMLProps } from 'react'
import { twMerge } from 'tailwind-merge'

export const Container = forwardRef<HTMLDivElement, { className?: string; children: ReactNode } & HTMLProps<HTMLDivElement>>(({ className, children, ...props }, ref) => {
	const baseClass = `flex w-5/6 max-w-[24rem] md:max-w-none flex-col gap-12 3xl:gap-24 5xl:gap-36
	lg:w-2/3 2xl:w-7/12 4xl:max-w-[108rem] md:p-12 lg:px-0 lg:pb-16
	`
	return (
		<div className={twMerge(baseClass, className)} ref={ref} {...props}>
			{children}
		</div>
	)
})
